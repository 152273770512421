// ---------------------------
// BEGIN - Polyfills

	// ---------------------------
	// BEGIN - Foreach

	  if ('NodeList' in window && !NodeList.prototype.forEach) {
	    console.info('polyfill for IE11');
	    NodeList.prototype.forEach = function (callback, thisArg) {
	      thisArg = thisArg || window;
	      for (var i = 0; i < this.length; i++) {
	        callback.call(thisArg, this[i], i, this);
	      }
	    };
	}

	// END - Foreach
	// ---------------------------

	// ---------------------------
	// BEGIN - Prepend

		(function (arr) {
		    arr.forEach(function (item) {
		        if (item.hasOwnProperty('prepend')) {
		            return;
		        }
		        Object.defineProperty(item, 'prepend', {
		            configurable: true,
		            enumerable: true,
		            writable: true,
		            value: function prepend() {
		                var argArr = Array.prototype.slice.call(arguments),
		                    docFrag = document.createDocumentFragment();

		                argArr.forEach(function (argItem) {
		                    var isNode = argItem instanceof Node;
		                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
		                });

		                this.insertBefore(docFrag, this.firstChild);
		            }
		        });
		    });
		})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

	// END - Prepend
	// ---------------------------


	// ---------------------------
	// BEGIN - Matches

		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function(s) {
					var matches = (this.document || this.ownerDocument).querySelectorAll(s),
						i = matches.length;
					while (--i >= 0 && matches.item(i) !== this) {}
					return i > -1;
				};
		}

	// END - Matches
	// ---------------------------

	// ---------------------------
	// BEGIN - Closest

		if (!Element.prototype.closest) {
		    Element.prototype.closest = function(s) {
		        var el = this;
		        if (!document.documentElement.contains(el)) return null;
		        do {
		            if (el.matches(s)) return el;
		            el = el.parentElement || el.parentNode;
		        } while (el !== null && el.nodeType === 1); 
		        return null;
		    };
		}

	// END - Closest
	// ---------------------------


	


// END - Polyfills
// ---------------------------